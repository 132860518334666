.centerDiv {
  display: grid;
  height: 100vh;
  place-items: center;
}
.centerBox{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 300px;
}
